import './styles.scss';

function ItemNull({ name, isPacked }) {
    if (isPacked) {
        return null;
      }
      return <li className="item">{name}</li>;
}

export default function PackingList() {
  return (
    <section>
      <h1>If isPacked is true, the component will return nothing, null. <br/>Otherwise, it will return JSX to render.</h1>
      <ul className="green-text large-text"> 
        <ItemNull isPacked={true} name="Space suit" />
        <ItemNull isPacked={false} name="Helmet with a golden leaf" />
        <ItemNull isPacked={false} name="Photo of Tam" />
      </ul>
    </section>
  );
}
