import { people } from "./data.js";
import { getImageUrl } from "./utils.js";
import styled from 'styled-components';

const RedText = styled.div`
  color: red;
  font-size: 32px;
`;
export default function DataList() {
    //console.log(people);
  const chemists = people;

  
  const listItems = chemists.map((personData) => (
    <li key={personData}>
      <img src={getImageUrl(personData)} alt={personData.name}  />
      <p>
        <b>{personData.name}:</b>
        {" " + personData.profession + " "}
        known for {personData.accomplishment}
      </p>
    </li>
  ));
  return (
    <>
      <section>
        <RedText>Rendering data from Data File</RedText>
        <ul>{listItems}</ul>
      </section>
    </>
  );
}
