import ItemTrue from './components/rendering/conditional/ItemTrue'
import ItemNull from './components/rendering/conditional/ItemNull'
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './App.css';
import ArrayList from './components/rendering/lists/ArrayList';
import DataList from './components/rendering/lists/DataList';

import Header from './layout/header';
import BasicExample from './components/card/card';

function App() {
  return (
    <div className="App">
      <Header/>
      <BasicExample/>
     <ItemTrue/> 
      <ItemNull/>
      <ArrayList/> 
      <DataList/>
    </div>
  );
}

export default App;
